import { FeatureData } from '@innedit/innedit';
import { PriceType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../components/View';
import Form from '../../../../../../containers/Espace/Form';
import params from '../../../../../../params/produit/price/index.json';
import TemplateEspace from '../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../utils/requireUser';

const PageEspaceProduitPrice: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, produitId, priceId },
}) => {
  const priceData = new FeatureData<PriceType>({
    espaceId,
    params,
    collectionName: 'prices',
    parentCollectionName: 'produits',
    parentId: produitId,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form<PriceType>
          displayAdd={false}
          displayNavigation={false}
          displayView={false}
          docId={priceId}
          itemPathnamePrefix={`/espaces/${espaceId}/produits/${produitId}/prix/`}
          itemPathnameSuffix=""
          model={priceData}
          name="price"
          params={{ produitId }}
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceProduitPrice);
